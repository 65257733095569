export const reviewsData = [
  {
    id: 1,
    imageSrc: require('../../Images/lastivka/reviewsLastivka/reviewLastivka1.jpg'),
  },
  {
    id: 2,
    imageSrc: require('../../Images/lastivka/reviewsLastivka/reviewLastivka2.jpg'),
  },
  {
    id: 3,
    imageSrc: require('../../Images/lastivka/reviewsLastivka/reviewLastivka3.jpg'),
  },
  {
    id: 4,
    imageSrc: require('../../Images/lastivka/reviewsLastivka/reviewLastivka4.jpg'),
  },
  {
    id: 5,
    imageSrc: require('../../Images/lastivka/reviewsLastivka/reviewLastivka5.jpg'),
  },
  {
    id: 6,
    imageSrc: require('../../Images/lastivka/reviewsLastivka/reviewLastivka6.jpg'),
  },
 

];
