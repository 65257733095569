import { Header } from 'components/Header/Header';
import { AboutProductLastivka } from 'components/lastivka/AboutProductLastivka/AboutProductLastivka';
import { ChooseColorLastivka } from 'components/lastivka/ChooseColorLastivka/ChooseColorLastivka';
import { MainLastivka } from 'components/lastivka/MainLastivka/MainLastivka';
import { ProductSizeLastivka } from 'components/lastivka/ProductSizeLastivka/ProductSizeLastivka';
import { ProposalLastivka } from 'components/lastivka/ProposalLastivka/ProposalLastivka';
import { ShoppingListLastivka } from 'components/lastivka/ShoppingListLastivka/ShoppingListLastivka';
import { useState } from 'react';
import { OurBenefits } from 'components/OurBenefits/OurBenefits';
import { Questions } from 'components/Questions/Questions';
import { Feadback } from 'components/Feadback/Feadback';
import { Contacts } from 'components/Contacts/Contacts';
import { Footer } from 'components/Footer/Footer';
import { ShoppingListButtonLastivka } from 'components/lastivka/ShoppingListButtonLastivka/ShoppingListButtonLastivka';
import { ReviewsLastivka } from 'components/lastivka/ReviewsLastivka/ReviewsLastivka';
export const Lastivka = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <ShoppingListLastivka
        modalOpen={modalOpen}
        modalClose={handleModalClose}
      />
      <ShoppingListButtonLastivka modalOpen={handleModalOpen} />
      <Header />
      <MainLastivka />
      <ProposalLastivka />
      <AboutProductLastivka />
      <ProductSizeLastivka />
      <ChooseColorLastivka id="chooseColorLastivka" modalOpen={handleModalOpen} />
      <ReviewsLastivka />
      <OurBenefits />
      <Questions />
      <Feadback />
      <Contacts />
      <Footer />
    </div>
  );
};
